@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');

body{
  background-color: rgb(32, 26, 26);
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
}

.app{
  display: grid;
  position: relative;
}

.css-1d3bbye {
  box-sizing: border-box;
  display: flex;
  flex-flow: nowrap !important; /* This disables flex-flow: wrap */
  width: 100%;
}
.css-11lq3yg-MuiGrid-root{
  display: flex;
  flex-wrap: nowrap !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/*Track*/
::-webkit-scrollbar-track{
  background: #E0E0E0;
}

/*Handle*/
::-webkit-scrollbar-thumb{
  background: #888;
}

/*Handle on hover*/
::-webkit-scrollbar-thumb:hover{
  background: #1a01f7;
}

.swal-custom-z-index {
  z-index: 9999 !important; /* Ensure a high z-index */
}