/* Styling for Loan Search Page */

.container {
    max-width: 1200px;
  }
  
  .card {
    border-radius: 10px;
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .table {
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  .thead-dark th {
    background-color: #343a40;
    color: #fff;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .alert-danger {
    margin-top: 1rem;
  }
  